$(document).ready(function() {

    /* eslint-disable */
        $('.header__menu__btn').on('click',  function () {
            $('body').toggleClass('header__menu__open')
        })
        $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
        $('.menu-item-touch').on('click', function () {
            $(this).parent('.menu-item').toggleClass('open')
        })

        $('.header__menu > ul > li > a').on('click',  function () {
            $('body').removeClass('header__menu__open')
        })

        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > 120) {
                $('.header').addClass('header--fix');
            }

            else {
                $('.header').removeClass('header--fix');
            }
        });

    /* eslint-enable */

});
