export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.header__search__toggle').on('click', function () {
            $('.header__search').toggleClass('open')
            $('.header__search__input input').focus()
        })

        if ($(window).width() < 768) {
            $('a[href*=\\#]').on('click', function(e){
                e.preventDefault();
                $('html,body').animate({scrollTop:$(this.hash).offset().top}, 'slow');
            });
        }
        else {
            $('a[href*=\\#]').on('click', function(e){
                e.preventDefault();
                $('html,body').animate({scrollTop: $(this.hash).offset().top - 100}, 'slow');
            });
        }

        $('.slimladen__link').click(function (){
            $('html, body').animate({scrollTop: $('#particulier').offset().top - 100}, 'slow');
            $('#particulier .nav li div.active').removeClass('active');
            $('#particulier .nav li:nth-child(2) div').addClass('active');
            $('#particulier .tab-content div').removeClass('active show');
            $('#particulier .tab-content div:nth-child(2)').addClass('active show');
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
